/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line no-shadow
export enum FilterSearchText {
  landUseCodes = 'Land Use',
  landZoneCodes = 'Land Zone',
  maxBathrooms = 'Maximum Bathrooms',
  maxBedrooms = 'Maximum Bedrooms',
  maxCapitalValue = 'Maximum Capital Value',
  maxDwellings = 'Maximum Dwellings',
  maxFloorArea = 'Maximum Floor Area',
  maxLandArea = 'Maximum Land Area',
  maxSaleDate = 'Latest Sale Date',
  maxSalePrice = 'Maximum Sale Price',
  maxStreetNumber = 'Maximum Street Number',
  maxYearBuilt = 'Maximum Year Built',
  maxSettlementDate = 'Maximum Settlement Date',
  maxRentalListedDate = 'Maximum Listing Date',
  maxListingDate = 'Maximum Listing Date',
  maxListingPrice = 'Maximum Listing Price',
  maxCarSpaces = 'Maximum Car Spaces',
  minSettlementDate = 'Minimum Settlement Date',
  minRentalListedDate = 'Minimum Listing Date',
  minListingDate = 'Minimum Listing Date',
  minListingPrice = 'Minimum Listing Price',
  minCarSpaces = 'Minimum Car Spaces',
  minBathrooms = 'Minimum Bathrooms',
  minBedrooms = 'Minimum Bedrooms',
  minCapitalValue = 'Minimum Capital Value',
  minDwellings = 'Minimum Dwellings',
  minFloorArea = 'Minimum Floor Area',
  minLandArea = 'Minimum Land Area',
  minSaleDate = 'Earliest Sale Date',
  minSalePrice = 'Minimum Sale Price',
  minStreetNumber = 'Minimum Street Number',
  minYearBuilt = 'Minimum Year Built',
  roofConstructionCodes = 'Roof type',
  wallConstructionCodes = 'Construction type',
  propertyTypeCodes = 'Property Type',
  streetNumberParity = 'Street Number Parity',
  'distanceFilter.Distance' = 'Radius Search',
}
