import {Injectable, Renderer2, signal} from '@angular/core';
import {NotificationType} from '@Shared/enums/notification-type';
import {PropertyDocumentRequest} from '@Shared/interfaces/api-requests/property-document-request';
import {DocumentSharedToUser} from '@Shared/interfaces/document-shared-to-user';
import {PropertyDocument} from '@Shared/interfaces/property-document';
import {PropertyDocumentType} from '@Shared/interfaces/property-document-type';
import {PropertyDocumentUri} from '@Shared/interfaces/property-document-uri';
import {PropertyID} from '@Shared/types/property-id';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs';
import {NotificationService} from '../notifications/notification.service';
import {RealEstateApiService} from '../real-estate-api/real-estate-api.service';
import {CmaId} from '@Shared/types/cma-id';
import {AccountId} from '@Shared/types/account-id';
import {environment} from '@Env/environment';
import {CreateUserRequest} from '@Pages/user-management/interfaces/create-user-request';
import {UserDetails} from '@Pages/user-management/interfaces/user-details';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {ProfileApiService} from '../profile-api/profile-api.service';
import {RequestResult} from '@Shared/interfaces/api-responses/request-result';

@Injectable({
  providedIn: 'root',
})
export class PropertyDocumentsService {
  private documents = new BehaviorSubject<PropertyDocument[]>([]);
  private mutations = new Subject<boolean>();
  private loading = new BehaviorSubject<boolean>(false);
  private uploadComplete = new Subject<boolean>();
  private requestUriComplete = new Subject<PropertyDocumentUri>();

  suburbInsightsLoading = signal(false);
  propertyInsightsLoading = signal(false);
  certificateOfTitleLoading = signal(false);
  onePagePropertyReportLoading = signal(false);

  constructor(
    private readonly realEstateService: RealEstateApiService,
    private readonly profileApiService: ProfileApiService,
    private readonly notificationService: NotificationService
  ) {}

  get documents$(): Observable<PropertyDocument[]> {
    return this.documents;
  }

  get mutations$(): Observable<boolean> {
    return this.mutations;
  }

  get loading$(): Observable<boolean> {
    return this.loading;
  }

  get uploadComplete$(): Observable<boolean> {
    return this.uploadComplete;
  }

  get requestUriComplete$(): Observable<PropertyDocumentUri> {
    return this.requestUriComplete;
  }

  reset() {
    this.mutations.next(true);
  }

  loadDocuments(propertyId: PropertyID) {
    this.realEstateService.getDocuments(propertyId).subscribe((response: PropertyDocument[]) => {
      this.documents.next(response);
    });
  }

  deleteDocument(propertyId: PropertyID, documentId: string) {
    this.realEstateService.deleteDocument(propertyId, documentId).subscribe(() => {
      this.reset();
    });
  }

  onDeleteDocumentClick(document: PropertyDocument, propertyId?: PropertyID) {
    if (!propertyId) {
      return;
    }
    this.notificationService
      .openMessageDialog(
        NotificationType.delete,
        'Are you sure you want to delete this document? If you have shared this with a vendor they will no longer be able to view it. ',
        'Delete Document?'
      )
      .afterClosed()
      .pipe(filter((result) => result === NotificationType.delete))
      .subscribe(() => {
        this.deleteDocument(propertyId ?? '', document.documentId);
      });
  }

  uploadDocuments(propertyId: PropertyID, documents: PropertyDocumentRequest[], cmaId?: CmaId) {
    this.loading.next(true);
    this.realEstateService
      .uploadDocuments(propertyId, documents, cmaId)
      .pipe(
        finalize(() => {
          this.loading.next(false);
          this.uploadComplete.next(true);
        })
      )
      .subscribe(() => {
        this.reset();
        this.notificationService.openNotificationDialog(NotificationType.success, 'Documents uploaded successfully');
      });
  }

  getDocumentUri(documentId: string) {
    this.realEstateService.getDocumentUri(documentId).subscribe((response: PropertyDocumentUri) => {
      this.requestUriComplete.next(response);
    });
  }

  filterDocumentTypes(documents: PropertyDocumentType[], territorialAuthority: string) {
    return documents.filter((documentType) =>
      documentType.territorialAuthorities ? documentType.territorialAuthorities.includes(territorialAuthority) : true
    );
  }

  shareDocument(request: DocumentSharedToUser) {
    this.realEstateService.shareDocument(request).subscribe(() => {
      this.notificationService.openNotificationDialog(NotificationType.success, 'Document was shared successfully');
    });
  }

  openDocument(documentUri: string | undefined, renderer: Renderer2, fileName: string): void {
    if (!documentUri) {
      return;
    }

    this.createLink(documentUri, renderer, fileName);
  }

  onShareDocuments(requests: DocumentSharedToUser[]): void {
    forkJoin(requests.map((request) => this.setupUserAccount(request)))
      .pipe(
        map((accountIds) => {
          return requests.map((request, index) => {
            return {
              ...request,
              accountId: accountIds[index],
            } as DocumentSharedToUser;
          });
        }),
        mergeMap((documents) => {
          return this.shareDocuments(documents);
        })
      )
      .subscribe(() => {
        this.notificationService.openNotificationDialog(NotificationType.success, 'Document was shared successfully');
        this.reset();
      });
  }

  setupUserAccount(request: DocumentSharedToUser): Observable<AccountId> {
    // User already has an account
    if (request.accountId) {
      return of(request.accountId);
    }

    // User account needs to be created
    const createUserRequest: CreateUserRequest = {
      tenantId: environment.vendorClientId,
      productId: environment.productId,
      firstName: request.firstName,
      lastName: request.lastName,
      emailAddress: request.email,
      roleIds: [],
    };
    return this.profileApiService.createVendorUser(createUserRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== HttpStatusCode.Conflict) {
          return throwError(() => error);
        }

        return this.profileApiService.getVendorUserByEmailAddress(request.email).pipe(
          map((userDetails: UserDetails): AccountId => {
            if (!userDetails) {
              throw Error('Error in getting user details');
            }

            if (createUserRequest.tenantId.toUpperCase() !== userDetails.tenantId.toUpperCase()) {
              throw Error('Error in user creation. User already exists under a different client');
            }

            // Vendor user already exists and was added as contact for another CMA
            return userDetails.accountId;
          })
        );
      })
    );
  }

  private shareDocuments(requests: DocumentSharedToUser[]): Observable<RequestResult[]> {
    return forkJoin(requests.map((request) => this.realEstateService.shareDocument(request)));
  }

  private createLink(documentUri: string, renderer: Renderer2, fileName: string) {
    const link = renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUri);
    link.setAttribute('download', fileName);
    link.click();
    link.remove();
  }
}
