import {Injectable} from '@angular/core';

// eslint-disable-next-line max-len
import {ApiEndpointsRealEstateService} from '@Core/services/api-endpoints-real-estate/api-endpoints-real-estate.service';
import {ApiHttpService} from '@Core/services/api-http/api-http.service';

import {ComparativeMarketAnalysis} from '@Shared/classes/comparative-market-analysis/comparative-market-analysis';
import {CreateCma} from '@Shared/interfaces/api-requests/create-cma';
import {SaveCma} from '@Shared/interfaces/api-requests/save-cma';
import {RequestResult} from '@Shared/interfaces/api-responses/request-result';
import {CmaId} from '@Shared/types/cma-id';
import {CmaSummary} from '@Shared/interfaces/api-responses/cma-summary';
import {CmaComparableProperty} from '@Shared/interfaces/cma-comparable-property';
import {DeleteComparableProperty} from '@Shared/interfaces/api-requests/delete-comparable-property';
import {AddComparableProperty} from '@Shared/interfaces/api-requests/add-comparable-property';
import {SaveComparableProperty} from '@Shared/interfaces/api-requests/save-comparable-property';
import {SavedSearch} from '@Shared/classes/saved-search/saved-search';
import {SaveNamedSearch} from '@Shared/interfaces/api-requests/save-named-search';
import {Vendor} from '@Shared/interfaces/vendor';
import {AddVendorToCma} from '@Shared/interfaces/api-requests/add-vendor-to-cma';
import {VendorID} from '@Shared/types/vendor-id';
import {RemoveVendorFromCma} from '@Shared/interfaces/api-requests/remove-vendor-from-cma';
import {SaveVendor} from '@Shared/interfaces/api-requests/save-vendor';
import {PropertyID} from '@Shared/types/property-id';
import {GetCmaPdf} from '@Shared/interfaces/api-requests/get-cma-pdf';
import {getTime} from '@Shared/constants/get-time';
import {CmaPdfResponse} from '@Shared/interfaces/api-responses/cma-pdf-response';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AddOrUpdateImageToCmaResult} from '@Shared/interfaces/api-responses/add-or-update-image-to-cma-result';
import {AddSectionToCmaResult} from '@Shared/interfaces/api-responses/add-section-to-cma-result';
import {CmaTemplatePageRequest} from '@Shared/interfaces/api-requests/cma-template-page-request';
import {CmaEditedField} from '@Shared/interfaces/cma-edited-field';
import {DocumentRequest} from '@Shared/interfaces/api-requests/document-request';
import {DocumentID} from '@Shared/types/document-id';
import {PropertyDocument} from '@Shared/interfaces/property-document';
import {Workflow} from '@Features/dashboard/interfaces/workflow';
import {CMAStatusUpdate} from '@Features/dashboard/interfaces/cma-status-update';
import {PropertyDocumentRequest} from '@Shared/interfaces/api-requests/property-document-request';
import {ImageUri} from '@Shared/interfaces/image-uri';
import {CmaStatusType} from '@Shared/enums/cma-status-type';
import {PropertyDocumentUri} from '@Shared/interfaces/property-document-uri';
import {CmaTemplate} from '@Shared/interfaces/cma-template';
import {DocumentSharedToUser} from '@Shared/interfaces/document-shared-to-user';
import {TitleDocument} from '@Shared/interfaces/title-document';
import {OrderRequest} from '@Shared/interfaces/api-requests/order-request';
import {OrderResponse} from '@Shared/interfaces/api-responses/order-response';
import {CmaContactDetails} from '@Shared/interfaces/api-responses/cma-contact-details';
import {SharedDocument} from '@Shared/interfaces/shared-document';
import {VendorCmaSummary} from '@Features/vendor-portal/interfaces/vendor-cma-summary';
import {CmaType} from '@Shared/enums/cma-type';
import {CmaTemplatePage} from '@Shared/interfaces/cma-template-page';
import {AzureToken} from '@Shared/interfaces/azure-token';
import {CmaFilterType} from '@Shared/enums/CmaFilterType';
import {EditDocumentRequest} from '@Shared/interfaces/api-requests/edit-document';
import {ArchiveCmaRequest} from '@Shared/interfaces/api-requests/archive-cma-request';
import {PropertyImageOrderRequest} from '@Shared/interfaces/api-requests/property-image-order-request';
import {PropertyImage} from '@Shared/interfaces/property-image';
import {ImageId} from '@Shared/types/image-id';
import {UpdateComparablePropertySortOrder} from '@Shared/interfaces/api-requests/update-comparable-property-sort-order';
import {ImageCacheService} from '../image-cache/image-cache.service';
import {UpdateSavedSearch} from '@Shared/interfaces/api-requests/update-saved-search';
import {AutoCompleteItem} from '@Shared/types/auto-complete-item';
import {SaveRecentSearches} from '@Shared/interfaces/api-requests/save-recent-searches';
import {RecentSearchesResponse} from '@Shared/interfaces/api-responses/recent-searches-response';
import {RecentlyViewedProperty} from '@Shared/types/recently-viewed-property';
import {CreateMapZoneRequest} from '@Shared/interfaces/api-requests/create-map-zone';
import {MapZone} from '@Shared/interfaces/api-responses/map-zone';
import {UpdateMapZoneRequest} from '@Shared/interfaces/api-requests/update-map-zone-request';
import {FolioNumberRequest} from '@Shared/interfaces/api-requests/folio-number-request';
import {FolioNumberResponse} from '@Shared/interfaces/api-responses/folio-number-response';
import {ComparativeMarketAnalysisSummary} from '@Shared/interfaces/api-responses/comparative-market-analysis-summary';
import {SuburbReportRequest} from '@Shared/interfaces/api-requests/suburb-report-request';
import {ReportRequest} from '@Shared/interfaces/api-requests/report-request';
import {environment} from '@Env/environment';
import {GenerateTaxReceiptRequest} from '@Shared/interfaces/api-requests/generate-tax-receipt-request';
import {GenerateDigitalCmaUrlResponse} from '@Shared/interfaces/api-responses/generate-digital-cma-url-response';
import {WebCmaPdfStatusResponse} from '@Shared/interfaces/api-responses/web-cma-pdf-status-response';
import {FinaliseCmaResult} from '@Shared/interfaces/api-responses/finalise-cma-result';
import {ExportSearchResultRequest} from '@Shared/interfaces/api-requests/export-search-result-request';
import {ContentType} from '@Shared/enums/content-type';
import {ClientProfile} from '@Shared/interfaces/client-profile';
import {CheckFeatureQuotaRequest} from '@Shared/interfaces/api-requests/check-feature-quota-request';
import {OrderID} from '@Shared/types/order-id';
import {OrderItem} from '@Shared/interfaces/api-responses/get-order-items-by-order-id';
import {PropertyReportRequest} from '@Shared/interfaces/api-requests/property-report-request';

@Injectable({
  providedIn: 'root',
})
export class RealEstateApiService {
  constructor(
    private readonly apiHttpService: ApiHttpService,
    private readonly apiEndpointsService: ApiEndpointsRealEstateService,
    private readonly imageCacheService: ImageCacheService
  ) {}

  getOrderItemsByOrderId(orderId: OrderID): Observable<OrderItem[]> {
    return this.apiHttpService.get(this.apiEndpointsService.getOrderItemsByOrderId(orderId));
  }

  addComparableProperty(request: AddComparableProperty): Observable<CmaComparableProperty> {
    return this.apiHttpService.post<CmaComparableProperty>(this.apiEndpointsService.addComparableProperty(), request);
  }

  updateComparablePropertySortOrder(request: UpdateComparablePropertySortOrder): Observable<RequestResult> {
    return this.apiHttpService.patch<RequestResult>(
      this.apiEndpointsService.updateComparablePropertySortOrder(request.cmaId),
      request
    );
  }

  addSavedSearch(request: SaveNamedSearch): Observable<SavedSearch> {
    return this.apiHttpService.post<SavedSearch>(this.apiEndpointsService.addSavedSearch(), request);
  }

  addVendorToCma(cmaId: CmaId, request: AddVendorToCma): Observable<Vendor> {
    return this.apiHttpService.post<Vendor>(this.apiEndpointsService.addVendorToCma(cmaId), request);
  }

  createCma(request: CreateCma) {
    return this.apiHttpService.post<CmaSummary>(this.apiEndpointsService.createCma(), request);
  }

  getCma(cmaId: CmaId): Observable<ComparativeMarketAnalysis> {
    return this.apiHttpService
      .get<ComparativeMarketAnalysis>(this.apiEndpointsService.getCma(cmaId), false)
      .pipe(map((cma) => new ComparativeMarketAnalysis(cma)));
  }

  getCmaListByPropertyId(propertyId: PropertyID): Observable<ComparativeMarketAnalysisSummary[]> {
    return this.apiHttpService.get<ComparativeMarketAnalysisSummary[]>(
      this.apiEndpointsService.getCmaListByPropertyId(propertyId),
      false
    );
  }

  getCmaListByPropertyIdAndCmaType(propertyId: PropertyID, cmaType: CmaType): Observable<ComparativeMarketAnalysis[]> {
    return this.apiHttpService.get<ComparativeMarketAnalysis[]>(
      this.apiEndpointsService.getCmaListByPropertyIdAndCmaType(propertyId, cmaType),
      false
    );
  }

  getCmaPdf(request: GetCmaPdf): Observable<CmaPdfResponse> {
    return this.apiHttpService.post<CmaPdfResponse>(this.apiEndpointsService.getCmaPdf(request.cmaId), request);
  }

  getSavedSearches(): Observable<SavedSearch[]> {
    return this.apiHttpService
      .get<SavedSearch[]>(this.apiEndpointsService.getSavedSearches(), false)
      .pipe(
        map((searchArray) =>
          searchArray
            .map((search) => new SavedSearch(search))
            .sort((a, b) => getTime(b.lastRunDate ?? b.created) - getTime(a.lastRunDate ?? a.created))
        )
      );
  }

  deleteComparableProperty(request: DeleteComparableProperty): Observable<RequestResult> {
    return this.apiHttpService.delete<RequestResult>(
      this.apiEndpointsService.deleteComparableProperty(request.comparablePropertyId),
      request
    );
  }

  removeVendorFromCma(cmaId: CmaId, vendorId: VendorID): Observable<RequestResult> {
    const request: RemoveVendorFromCma = {
      cmaId,
      vendorId,
    };

    return this.apiHttpService.delete<RequestResult>(this.apiEndpointsService.removeVendorFromCma(cmaId), request);
  }

  deleteSaveSearch(id: string): Observable<RequestResult> {
    const uri = this.apiEndpointsService.saveSearch(id);
    return this.apiHttpService.delete<RequestResult>(uri);
  }

  finalisePdf(request: GetCmaPdf): Observable<FinaliseCmaResult> {
    return this.apiHttpService.post<RequestResult>(this.apiEndpointsService.finalisePdf(request.cmaId), request);
  }

  saveComparableProperty(cmaId: CmaId, comparableProperty: CmaComparableProperty): Observable<CmaComparableProperty> {
    const uri = this.apiEndpointsService.saveComparableProperty();
    const request: SaveComparableProperty = {
      cmaId,
      cmaFilterType: comparableProperty.cmaFilterType as CmaFilterType,
      comment: comparableProperty.comment,
      fullAddress: comparableProperty.fullAddress,
      propertyId: comparableProperty.propertyId,
      sortOrder: comparableProperty.sortOrder,
    };

    return this.apiHttpService.post<CmaComparableProperty>(uri, request);
  }

  saveDraftCMA(cma: ComparativeMarketAnalysis): Observable<RequestResult> {
    const uri = this.apiEndpointsService.saveDraftCMA(cma.id);
    const filters = cma.filters?.map((item) => item.toSaveVersion());

    const request: SaveCma = {
      id: cma.id,
      commissionSchedule: cma.commissionSchedule,
      enableVendorView: cma.enableVendorView,
      estimatedHighPrice: cma.estimatedHighPrice,
      estimatedLowPrice: cma.estimatedLowPrice,
      priceRange: cma.priceRange,
      priceRangeToggled: cma.priceRangeToggled,
      preparedForName: cma.preparedForName,
      preparedForPrefix: cma.preparedForPrefix,
      preparedForToggled: cma.preparedForToggled,
      filters,
      name: cma.name,
      preparedBy: cma.preparedBy,
      targetPropertyId: cma.targetPropertyId,
      templateId: cma.templateId,
      totalCommissionHighRange: cma.totalCommissionHighRange,
      totalCommissionLowRange: cma.totalCommissionLowRange,
      statisticsEmailEnabled: cma.statisticsEmailEnabled,
    };

    return this.apiHttpService.patch<RequestResult>(uri, request);
  }

  saveVendor(vendor: Vendor): Observable<RequestResult> {
    const uri = this.apiEndpointsService.saveVendor(vendor.vendorId);
    const request: SaveVendor = {
      vendorId: vendor.vendorId,
      email: vendor.email,
      firstName: vendor.firstName,
      lastName: vendor.lastName,
    };

    return this.apiHttpService.patch<RequestResult>(uri, request);
  }

  updateSavedSearch(savedSearch: SavedSearch): Observable<RequestResult> {
    const uri = this.apiEndpointsService.saveSearch(savedSearch.searchId);
    const request: UpdateSavedSearch = {
      searchId: savedSearch.searchId,
      lastRunDate: savedSearch.lastRunDate ?? new Date(),
      propertyCount: savedSearch.propertyCount ?? 0,
      name: savedSearch.name,
    };

    return this.apiHttpService.patch<RequestResult>(uri, request);
  }

  savePropertyImageToCma(cmaId: CmaId, propertyId: PropertyID, image: File): Observable<ImageUri> {
    const uri = this.apiEndpointsService.savePropertyImageToCma(cmaId);

    const formData = new FormData();

    formData.append('cmaId', cmaId);
    formData.append('propertyId', propertyId);
    formData.append('imageFile', image, image.name);
    this.imageCacheService.removeImageFromCache(propertyId);
    return this.apiHttpService.postFormData<AddOrUpdateImageToCmaResult>(uri, formData);
  }

  updateCmaPropertyImage(cmaId: CmaId, propertyId: PropertyID, imageId: ImageId, image: File): Observable<ImageUri> {
    const uri = this.apiEndpointsService.updateCmaPropertyImage(cmaId, imageId);

    const formData = new FormData();

    formData.append('cmaId', cmaId);
    formData.append('propertyId', propertyId);
    formData.append('imageId', imageId);
    formData.append('imageFile', image, image.name);
    return this.apiHttpService.putFormData<AddOrUpdateImageToCmaResult>(uri, formData);
  }

  getCmaTemplatePages(cmaId: CmaId): Observable<CmaTemplatePage[]> {
    const uri = this.apiEndpointsService.getCmaTemplatePages(cmaId);

    return this.apiHttpService.get<CmaTemplatePage[]>(uri, false);
  }

  addCmaTemplatePage(cmaId: CmaId, pageId: number): Observable<AddSectionToCmaResult> {
    const uri = this.apiEndpointsService.addCmaTemplatePage(cmaId);

    const request: CmaTemplatePageRequest = {
      cmaId,
      pageId,
    };

    return this.apiHttpService.post<AddSectionToCmaResult>(uri, request);
  }

  removeCmaTemplatePage(cmaId: CmaId, pageId: number): Observable<RequestResult> {
    const uri = this.apiEndpointsService.removeCmaTemplatePage(cmaId);

    const request: CmaTemplatePageRequest = {
      cmaId,
      pageId,
    };

    return this.apiHttpService.delete<RequestResult>(uri, request);
  }

  addEditedPropertyFieldToCma(field: CmaEditedField) {
    const uri = this.apiEndpointsService.addEditedPropertyFieldToCma(field.cmaId);

    const request: CmaEditedField = field;

    return this.apiHttpService.post<CmaEditedField[]>(uri, request);
  }

  getVendorCmaSummaries(): Observable<VendorCmaSummary[]> {
    return this.apiHttpService.get<VendorCmaSummary[]>(this.apiEndpointsService.getVendorCmaSummaries());
  }

  getSharedDocumentsByCmaId(cmaId: CmaId): Observable<SharedDocument[]> {
    return this.apiHttpService.get<SharedDocument[]>(this.apiEndpointsService.getSharedDocumentsByCmaId(cmaId));
  }

  deleteDocument(propertyId: PropertyID, documentId: DocumentID): Observable<RequestResult> {
    const request: DocumentRequest = {
      propertyId,
      documentId,
    };
    return this.apiHttpService.delete<RequestResult>(this.apiEndpointsService.deleteDocument(propertyId), request);
  }

  shareDocument(request: DocumentSharedToUser): Observable<RequestResult> {
    return this.apiHttpService.post<RequestResult>(
      this.apiEndpointsService.shareDocument(request.propertyId, request.documentId),
      request
    );
  }

  getDocuments(propertyId: PropertyID): Observable<PropertyDocument[]> {
    return this.apiHttpService.get<PropertyDocument[]>(this.apiEndpointsService.getDocuments(propertyId), false);
  }

  uploadDocuments(
    propertyId: PropertyID,
    propertyDocumentRequest: PropertyDocumentRequest[],
    cmaId?: CmaId
  ): Observable<PropertyDocument[]> {
    const uri = this.apiEndpointsService.uploadDocuments(propertyId);

    const formData = new FormData();

    formData.append('propertyId', propertyId);
    if (cmaId) {
      formData.append('cmaId', cmaId);
    }
    propertyDocumentRequest.forEach((request, index) => {
      formData.append(`documents[${index}].document`, request.file, request.name);
      formData.append(`documents[${index}].documentType`, request.type);
    });

    return this.apiHttpService.postFormData<PropertyDocument[]>(uri, formData);
  }

  getDocumentUri(documentId: DocumentID): Observable<PropertyDocumentUri> {
    return this.apiHttpService.get<PropertyDocumentUri>(this.apiEndpointsService.getDocumentUri(documentId), false);
  }

  getDashboardItems(status: CmaStatusType, accountId: string): Observable<Workflow> {
    return this.apiHttpService.get<Workflow>(this.apiEndpointsService.getWorkflow(status, accountId), false);
  }

  updateCMAStatus(request: CMAStatusUpdate): Observable<RequestResult> {
    return this.apiHttpService.post<RequestResult>(this.apiEndpointsService.updateCMAStatus(request.cmaId), request);
  }

  getCmaTemplates(cmaType: CmaType): Observable<CmaTemplate[]> {
    return this.apiHttpService.get<CmaTemplate[]>(this.apiEndpointsService.getCmaTemplates(cmaType));
  }

  getTitleOrders(propertyId: PropertyID): Observable<TitleDocument[]> {
    return this.apiHttpService.get<TitleDocument[]>(this.apiEndpointsService.getTitleOrders(propertyId));
  }

  placeOrder(order: OrderRequest): Observable<OrderResponse> {
    const uri = this.apiEndpointsService.placeOrder();
    return this.apiHttpService.post<OrderResponse>(uri, order);
  }

  getCmaContactDetails(cmaId: CmaId): Observable<CmaContactDetails> {
    return this.apiHttpService.get<CmaContactDetails>(this.apiEndpointsService.getCmaContactDetails(cmaId), false);
  }

  getAllPropertyImages(
    propertyId: PropertyID,
    countryCode: string,
    includeListingImages: boolean
  ): Observable<PropertyImage[]> {
    const uri = this.apiEndpointsService.getAllPropertyImages(propertyId, countryCode, includeListingImages);
    return this.apiHttpService.get<PropertyImage[]>(uri, false);
  }

  getAzureMapsToken(): Observable<AzureToken> {
    return this.apiHttpService.get<AzureToken>(this.apiEndpointsService.getAzureMapsToken(), false);
  }

  updateDocument(documentRequest: EditDocumentRequest): Observable<PropertyDocument> {
    return this.apiHttpService.patch<PropertyDocument>(
      this.apiEndpointsService.updateDocument(documentRequest.documentId),
      documentRequest
    );
  }

  archiveCma(request: ArchiveCmaRequest): Observable<ComparativeMarketAnalysis> {
    return this.apiHttpService.post<ComparativeMarketAnalysis>(
      this.apiEndpointsService.archiveCma(request.cmaId),
      request
    );
  }

  deleteCustomImage(propertyId: string, imageUri: string): Observable<RequestResult> {
    this.imageCacheService.removeImageFromCache(propertyId);
    return this.apiHttpService.delete<RequestResult>(this.apiEndpointsService.deleteCustomImage(propertyId, imageUri));
  }

  savePropertyImageOrder(propertyId: PropertyID, request: PropertyImageOrderRequest) {
    this.imageCacheService.removeImageFromCache(propertyId);
    return this.apiHttpService.post<RequestResult>(
      this.apiEndpointsService.savePropertyImageOrder(propertyId),
      request
    );
  }

  getRecentSearches(): Observable<AutoCompleteItem[]> {
    return this.apiHttpService
      .get<RecentSearchesResponse>(this.apiEndpointsService.getRecentSearches(), false)
      .pipe(map((response) => JSON.parse(response.json ?? '[]')));
  }

  saveRecentSearches(recentSearches: AutoCompleteItem[]): Observable<null> {
    const body: SaveRecentSearches = {
      json: JSON.stringify(recentSearches),
    };
    return this.apiHttpService.put(this.apiEndpointsService.saveRecentSearches(), body);
  }

  getRecentlyViewed() {
    return this.apiHttpService.get<RecentlyViewedProperty[]>(this.apiEndpointsService.getRecentlyViewed(), false);
  }

  addRecentlyViewedProperty(request: RecentlyViewedProperty) {
    const uri = this.apiEndpointsService.getRecentlyViewed();
    return this.apiHttpService.post<null>(uri, request);
  }

  deleteRecentlyViewedProperty(id: string) {
    return this.apiHttpService.delete<RequestResult>(this.apiEndpointsService.deleteRecentlyViewedProperty(id));
  }

  createMapZone(request: CreateMapZoneRequest): Observable<MapZone> {
    const uri = this.apiEndpointsService.createMapZone();
    return this.apiHttpService.post<MapZone>(uri, request);
  }

  updateMapZone(request: UpdateMapZoneRequest): Observable<null> {
    const uri = this.apiEndpointsService.updateMapZone(request.id);
    return this.apiHttpService.patch<null>(uri, request);
  }

  getMapZoneById(id: string): Observable<MapZone> {
    const uri = this.apiEndpointsService.getMapZoneById(id);
    return this.apiHttpService.get<MapZone>(uri, false);
  }

  getMapZones(): Observable<MapZone[]> {
    const uri = this.apiEndpointsService.getMapZones();
    return this.apiHttpService
      .get<MapZone[]>(uri, false)
      .pipe(
        map((mapZones) =>
          [...mapZones].sort((a, b) => getTime(b.lastRunDate ?? b.created) - getTime(a.lastRunDate ?? a.created))
        )
      );
  }

  deleteMapZone(mapZoneId: string): Observable<null> {
    return this.apiHttpService.delete<null>(this.apiEndpointsService.deleteMapZone(mapZoneId));
  }

  getSavedSearchesByMapZone(id: string): Observable<SavedSearch[]> {
    return this.apiHttpService.get<SavedSearch[]>(this.apiEndpointsService.getSavedSearchesByMapZone(id), false);
  }

  getFolioNumber(request: FolioNumberRequest): Observable<FolioNumberResponse> {
    const uri = this.apiEndpointsService.getFolioNumber(request);
    return this.apiHttpService.get<FolioNumberResponse>(uri);
  }

  getSuburbReport(propertyId: string, request: SuburbReportRequest): Observable<PropertyDocument> {
    return this.apiHttpService.post(this.apiEndpointsService.getSuburbReport(propertyId, request.suburbUid), request);
  }

  getPropertyInsightsReport(propertyId: PropertyID, request: ReportRequest): Observable<PropertyDocument> {
    const reportRequest: ReportRequest = {
      ...request,
      minComparable: environment.propertyReport.minComparableProperties,
      maxcomparable: environment.propertyReport.maxComparableProperties,
    };
    return this.apiHttpService.post(this.apiEndpointsService.getPropertyInsightsReport(propertyId), reportRequest);
  }

  getCertificateOfTitleSummaryReport(propertyId: PropertyID, request: ReportRequest): Observable<PropertyDocument> {
    return this.apiHttpService.post(this.apiEndpointsService.getCertificateOfTitleSummaryReport(propertyId), request);
  }

  sendTaxReceipt(request: GenerateTaxReceiptRequest): Observable<void> {
    const uri = this.apiEndpointsService.sendTaxReceipt();
    return this.apiHttpService.post(uri, request);
  }

  generateDigitalCmaUrl(
    cmaId: string,
    useCustomizeWebCmaPages: boolean,
    timezone: string
  ): Observable<GenerateDigitalCmaUrlResponse> {
    const uri = this.apiEndpointsService.generateDigitalCmaUrl(cmaId);
    return this.apiHttpService.post(uri, {cmaId, useCustomizeWebCmaPages, timezone});
  }

  getWebCmaPdfStatus(documentId: DocumentID): Observable<WebCmaPdfStatusResponse> {
    return this.apiHttpService.get<WebCmaPdfStatusResponse>(
      this.apiEndpointsService.getWebCmaPdfStatus(documentId),
      false,
      false
    );
  }

  getMyClientProfile(): Observable<ClientProfile> {
    return this.apiHttpService.get<ClientProfile>(this.apiEndpointsService.getMyClientProfile());
  }

  saveClientProfile(clientProfile: ClientProfile): Observable<ClientProfile> {
    return this.apiHttpService.post<ClientProfile>(this.apiEndpointsService.saveClientProfile(), clientProfile);
  }

  exportSearchResult(request: ExportSearchResultRequest): Observable<Blob> {
    const uri = this.apiEndpointsService.exportSearchResult();
    return this.apiHttpService.post<Blob>(uri, request, ContentType.pdf).pipe(
      map((res: Blob) => {
        return new Blob([res], {type: request.fileType === 'pdf' ? 'application/pdf' : 'text/csv'});
      })
    );
  }

  checkFeatureQuota(request: CheckFeatureQuotaRequest): Observable<boolean> {
    return this.apiHttpService.get<boolean>(this.apiEndpointsService.checkFeatureQuota(request), false, false);
  }

  getOnePagePropertyReport(propertyId: PropertyID, suburbId: string, fileName: string): Observable<PropertyDocument> {
    const request: PropertyReportRequest = {
      propertyId,
      suburbId,
      fileName,
      countryCode: environment.countryCode,
    };
    return this.apiHttpService.post(this.apiEndpointsService.getOnePagePropertyReport(propertyId), request);
  }
}
