import {FilterItems} from '@Shared/classes/filter-items/filter-items';
import {nameof} from '@Shared/helpers/name-of-helpers/name-of-helpers';

export const nzFilters = {
  filtersFallback: new FilterItems(),
  advancedFilters: new FilterItems([
    {filter: nameof<FilterItems>('lastSalePrice')},
    {filter: nameof<FilterItems>('lastSaleDate')},
    {filter: nameof<FilterItems>('capitalValue')},
    {filter: nameof<FilterItems>('dwellings')},
    {filter: nameof<FilterItems>('decadeBuilt')},
    {filter: nameof<FilterItems>('bedrooms')},
    {filter: nameof<FilterItems>('bathrooms')},
    {filter: nameof<FilterItems>('landArea')},
    {filter: nameof<FilterItems>('floorArea')},
    {filter: nameof<FilterItems>('roofConstruction')},
    {filter: nameof<FilterItems>('wallContruction')},
    {filter: nameof<FilterItems>('propertyType')},
    {filter: nameof<FilterItems>('houseNumber'), newLabel: 'Street number'},
  ]),
  filtersCMAPropertiesSold: new FilterItems([
    {filter: nameof<FilterItems>('lastSalePrice')},
    {filter: nameof<FilterItems>('lastSaleDate')},
    {filter: nameof<FilterItems>('capitalValue')},
    {filter: nameof<FilterItems>('dwellings')},
    {filter: nameof<FilterItems>('decadeBuilt')},
    {filter: nameof<FilterItems>('bedrooms')},
    {filter: nameof<FilterItems>('bathrooms')},
    {filter: nameof<FilterItems>('landArea')},
    {filter: nameof<FilterItems>('floorArea')},
    {filter: nameof<FilterItems>('roofConstruction')},
    {filter: nameof<FilterItems>('wallContruction')},
    {filter: nameof<FilterItems>('propertyType')},
    {filter: nameof<FilterItems>('houseNumber'), newLabel: 'Street number'},
  ]),
  filtersCMAPropertiesOnMarket: new FilterItems([
    {filter: nameof<FilterItems>('lastSalePrice')},
    {filter: nameof<FilterItems>('lastSaleDate')},
    {filter: nameof<FilterItems>('listingDate')},
    {filter: nameof<FilterItems>('capitalValue')},
    {filter: nameof<FilterItems>('dwellings')},
    {filter: nameof<FilterItems>('decadeBuilt')},
    {filter: nameof<FilterItems>('bedrooms')},
    {filter: nameof<FilterItems>('bathrooms')},
    {filter: nameof<FilterItems>('landArea')},
    {filter: nameof<FilterItems>('floorArea')},
    {filter: nameof<FilterItems>('roofConstruction')},
    {filter: nameof<FilterItems>('wallContruction')},
    {filter: nameof<FilterItems>('propertyType')},
    {filter: nameof<FilterItems>('houseNumber'), newLabel: 'Street number'},
  ]),
  filtersRentalCMAPropertiesForLease: new FilterItems([
    {filter: nameof<FilterItems>('weeklyRent')},
    {filter: nameof<FilterItems>('rentalListedDate')},
    {filter: nameof<FilterItems>('capitalValue')},
    {filter: nameof<FilterItems>('dwellings')},
    {filter: nameof<FilterItems>('decadeBuilt')},
    {filter: nameof<FilterItems>('bedrooms')},
    {filter: nameof<FilterItems>('bathrooms')},
    {filter: nameof<FilterItems>('landArea')},
    {filter: nameof<FilterItems>('floorArea')},
    {filter: nameof<FilterItems>('roofConstruction')},
    {filter: nameof<FilterItems>('wallContruction')},
    {filter: nameof<FilterItems>('propertyType')},
    {filter: nameof<FilterItems>('houseNumber'), newLabel: 'Street number'},
  ]),
  filtersSOIPropertiesSold: new FilterItems([]),
  filtersSearchOptionAllAndSold: new FilterItems([
    {filter: nameof<FilterItems>('propertyType'), sortOrder: 1},
    {filter: nameof<FilterItems>('lastSaleDate'), sortOrder: 3},
    {filter: nameof<FilterItems>('decadeBuilt'), sortOrder: 5},
    {filter: nameof<FilterItems>('bathrooms'), sortOrder: 7},
    {filter: nameof<FilterItems>('landArea'), sortOrder: 9},
    {filter: nameof<FilterItems>('houseNumber'), sortOrder: 11},
    {filter: nameof<FilterItems>('lastSalePrice'), sortOrder: 2},
    {filter: nameof<FilterItems>('settlementDate'), sortOrder: 4},
    {filter: nameof<FilterItems>('bedrooms'), sortOrder: 6},
    {filter: nameof<FilterItems>('carSpaces'), sortOrder: 8},
    {filter: nameof<FilterItems>('floorArea'), sortOrder: 10},
  ]),
  filtersSearchOptionForSale: new FilterItems([
    {filter: nameof<FilterItems>('propertyType'), sortOrder: 1},
    {filter: nameof<FilterItems>('listingDate'), sortOrder: 3},
    {filter: nameof<FilterItems>('bedrooms'), sortOrder: 5},
    {filter: nameof<FilterItems>('carSpaces'), sortOrder: 7},
    {filter: nameof<FilterItems>('floorArea'), sortOrder: 9},
    {filter: nameof<FilterItems>('listingPrice'), sortOrder: 2},
    {filter: nameof<FilterItems>('decadeBuilt'), sortOrder: 4},
    {filter: nameof<FilterItems>('bathrooms'), sortOrder: 6},
    {filter: nameof<FilterItems>('landArea'), sortOrder: 8},
    {filter: nameof<FilterItems>('houseNumber'), sortOrder: 10},
  ]),
  filtersSearchOptionWithdrawn: new FilterItems([
    {filter: nameof<FilterItems>('propertyType'), sortOrder: 1},
    {filter: nameof<FilterItems>('listingDate'), sortOrder: 3},
    {filter: nameof<FilterItems>('bedrooms'), sortOrder: 6},
    {filter: nameof<FilterItems>('carSpaces'), sortOrder: 8},
    {filter: nameof<FilterItems>('floorArea'), sortOrder: 10},
    {filter: nameof<FilterItems>('listingPrice'), sortOrder: 2},
    {filter: nameof<FilterItems>('listingWithdrawnDate'), sortOrder: 4},
    {filter: nameof<FilterItems>('decadeBuilt'), sortOrder: 5},
    {filter: nameof<FilterItems>('bathrooms'), sortOrder: 7},
    {filter: nameof<FilterItems>('landArea'), sortOrder: 9},
    {filter: nameof<FilterItems>('houseNumber'), sortOrder: 11},
  ]),
};
