import {Component, OnDestroy, Renderer2, ViewContainerRef, inject} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {AuthorizationService} from '@Core/services/authorization/authorization.service';
import {AutoLogoutService} from '@Core/services/auto-logout/auto-logout.service';
import {from, Subject, takeUntil, take} from 'rxjs';
import {AuthenticationService} from './core/services/authentication/authentication.service';
import {RouterEventsService} from '@Core/services/router-events/router-events.service';
import {DOCUMENT} from '@angular/common';
import {PropertyService} from '@Core/services/property-service/property.service';
import {LaunchDarklyInitializer} from '@Core/services/feature-flag/launch-darkly-initializer';
import {NotificationService} from '@Core/services/notifications/notification.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PropertyDocumentsService} from '@Core/services/property-documents/property-documents.service';
import {SnackBarActionType} from '@Shared/enums/snackbar-action-type';
import {propertyDocumentSchema} from '@Shared/zod-schemas/property-document-schema';
import {SnackBarMessageAction} from '@Shared/interfaces/snackbar-message-options';
import {downloadSearchResultSchema} from '@Shared/zod-schemas/download-search-result-schema';
import {OpenTelemetryConfigService} from '@Core/services/open-telemetry/open-telemetry-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  isLoggedIn = false;
  showHeader = false;
  title = 'real-estate';

  document = inject(DOCUMENT);
  sections$ = inject(PropertyService).sections$;

  private readonly destroy$ = new Subject();
  private readonly notificationService = inject(NotificationService);
  private readonly propertyDocumentsService = inject(PropertyDocumentsService);
  private readonly renderer = inject(Renderer2);

  constructor(
    private readonly authService: AuthenticationService,
    private router: Router,
    private authorizationService: AuthorizationService,
    private autoLogoutService: AutoLogoutService,
    public viewContainerRef: ViewContainerRef,
    private routerEventsService: RouterEventsService,
    private readonly launchDarklyInitializer: LaunchDarklyInitializer,
    private readonly openTelemetryConfigService: OpenTelemetryConfigService
  ) {
    this.authService.configure();
    from(this.authService.tryLoginCodeFlow()).subscribe(() => {
      if (this.authService.isLoggedIn) {
        this.isLoggedIn = true;
      } else {
        this.routerEventsService.listenForEvents();
        this.router.navigate(['/login']);
      }
    });

    router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url === '/login') {
          this.showHeader = false;
        } else {
          this.showHeader = true;
        }
      }
    });

    this.autoLogoutService.startWatching();

    this.notificationService.action$.pipe(takeUntilDestroyed()).subscribe((action) => {
      switch (action.type) {
        case SnackBarActionType.viewPropertyDocument:
          this.processViewPropertyDocumentAction(action);
          break;
        case SnackBarActionType.viewDigitalCma:
          this.processViewDigitalCmaAction(action);
          break;
        case SnackBarActionType.downloadSearchResults:
          this.processDownloadSearchResults(action);
          break;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  scrollToSection(section: string) {
    const sectionTo = this.document.getElementById(section);
    sectionTo?.scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  private processViewPropertyDocumentAction(action: SnackBarMessageAction) {
    const document = propertyDocumentSchema.parse(action.payload);
    if (document) {
      this.propertyDocumentsService.getDocumentUri(document.documentId);
      this.propertyDocumentsService.requestUriComplete$.pipe(take(1)).subscribe((documentUri) => {
        window.open(documentUri.uri, '_blank');
      });
      this.scrollToSection('Documents');
    }
  }

  private processViewDigitalCmaAction(action: SnackBarMessageAction) {
    const previewUrl = action.payload as string;
    if (previewUrl) {
      window.open(previewUrl, '_blank');
    }
    this.scrollToSection('Appraisals');
  }

  private processDownloadSearchResults(action: SnackBarMessageAction) {
    const payload = downloadSearchResultSchema.parse(action.payload);
    this.propertyDocumentsService.openDocument(payload.url, this.renderer, payload.fileName);
  }
}
