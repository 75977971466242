@if (appearance === 'primary') {
  <button
    mat-flat-button
    color="primary"
    ngClass=" {{ size }}"
    color="{{ status }}"
    [disabled]="disabled"
    [type]="type"
    (click)="buttonClick.emit()"
  >
    @if (prefix) {
      <mat-icon fontSet="material-icons-outlined">{{ prefix }}</mat-icon>
    }

    @if (svgIcon) {
      <mat-icon
        class="svg-icon"
        [svgIcon]="svgIcon"
      ></mat-icon>
    }
    {{ label }}<mat-icon>{{ suffix }}</mat-icon>
  </button>
}
@if (appearance === 'secondary') {
  <button
    mat-stroked-button
    color="primary"
    ngClass=" {{ size }}"
    color="{{ status }}"
    [disabled]="disabled"
    [type]="type"
    (click)="buttonClick.emit()"
  >
    @if (prefix) {
      <mat-icon fontSet="material-icons-outlined">{{ prefix }}</mat-icon>
    }

    @if (svgIcon) {
      <mat-icon
        class="svg-icon"
        [svgIcon]="svgIcon"
      ></mat-icon>
    }
    {{ label }}<mat-icon>{{ suffix }}</mat-icon>
  </button>
}
@if (appearance === 'tertiary') {
  <button
    mat-button
    color="primary"
    ngClass=" {{ size }}"
    color="{{ status }}"
    [disabled]="disabled"
    [type]="type"
    (click)="buttonClick.emit()"
  >
    @if (prefix) {
      <mat-icon fontSet="material-icons-outlined">{{ prefix }}</mat-icon>
    }

    @if (svgIcon) {
      <mat-icon
        class="svg-icon"
        [svgIcon]="svgIcon"
      ></mat-icon>
    }
    {{ label }}<mat-icon>{{ suffix }}</mat-icon>
  </button>
}
