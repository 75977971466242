import {FilterItem} from '@Shared/interfaces/filter-item';

export class FilterItems {
  lastSalePrice: FilterItem = {visible: false, label: 'Last sale price'};
  lastSaleDate: FilterItem = {visible: false, label: 'Last sale date'};
  listingDate: FilterItem = {visible: false, label: 'Listing date'};
  listingPrice: FilterItem = {visible: false, label: 'Listing price'};
  capitalValue: FilterItem = {visible: false, label: 'Capital value'};
  dwellings: FilterItem = {visible: false, label: 'Dwellings'};
  decadeBuilt: FilterItem = {visible: false, label: 'Decade built'};
  bedrooms: FilterItem = {visible: false, label: 'Bedrooms'};
  bathrooms: FilterItem = {visible: false, label: 'Bathrooms'};
  landArea: FilterItem = {visible: false, label: 'Land area'};
  floorArea: FilterItem = {visible: false, label: 'Floor area'};
  roofConstruction: FilterItem = {visible: false, label: 'Roof construction'};
  wallContruction: FilterItem = {visible: false, label: 'Wall construction'};
  propertyType: FilterItem = {visible: false, label: 'Property type'};
  houseNumber: FilterItem = {visible: false, label: 'House number'};
  weeklyRent: FilterItem = {visible: false, label: 'Weekly rent'};
  rentalListedDate: FilterItem = {visible: false, label: 'Listing date'};

  carSpaces: FilterItem = {visible: false, label: 'Car spaces'};
  settlementDate: FilterItem = {visible: false, label: 'Settlement date'};
  listingWithdrawnDate: FilterItem = {visible: false, label: 'Withdrawn On'};

  constructor(filters?: {filter: string; newLabel?: string; sortOrder?: number}[]) {
    if (filters === undefined) {
      Object.keys(this).forEach((currentKey) => {
        const key = currentKey as keyof FilterItems;
        this[key].visible = true;
      });
    } else {
      filters.forEach((currentFilter) => {
        const key = currentFilter.filter as keyof FilterItems;
        this[key].visible = true;

        if (currentFilter.newLabel) {
          this[key].label = currentFilter.newLabel;
        }

        if (currentFilter.sortOrder) {
          this[key].sortOrder = currentFilter.sortOrder;
        }
      });
    }
  }
}
