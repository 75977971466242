import {Directive, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, of} from 'rxjs';

@Directive({
  selector: '[appLazyLoad]',
})
export class LazyLoadDirective implements OnInit {
  @Input() disableLazyLoad = false;
  @Output() imageLoaded = new EventEmitter<string>();

  private image$?: Observable<string>;
  private supports = false;

  constructor(private el: ElementRef<HTMLImageElement>) {
    this.supports = 'loading' in HTMLImageElement.prototype;
  }

  ngOnInit(): void {
    if (this.supports && !this.disableLazyLoad) {
      this.el.nativeElement.setAttribute('loading', 'lazy');
    }
  }

  @Input() set src(value: string | Observable<string> | undefined | null) {
    if (!value) {
      return;
    }

    this.image$ = typeof value === 'string' ? of(value) : value;
    if ((this.supports || this.disableLazyLoad) && value) {
      this.loadImage(this.image$);
    } else if (value) {
      this.lazyLoadImage();
    }
  }

  private lazyLoadImage(): void {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(({isIntersecting}) => {
        if (isIntersecting && this.image$) {
          this.loadImage(this.image$);
          observer.unobserve(this.el.nativeElement);
        }
      });
    });
    observer.observe(this.el.nativeElement);
  }

  private loadImage(src: Observable<string>): void {
    src.subscribe((imageUri) => {
      if (imageUri) {
        this.el.nativeElement.setAttribute('src', imageUri);
        this.imageLoaded.emit(imageUri);
      }
    });
  }
}
