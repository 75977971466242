export const currencyCharactersRegex = /[\.,\$]/g;
export const currencyRegex = /\$\ ?[+-]?\d{1,3}(?:,?\d)*(?:\.\d{1,2})?/;
export const nonNumericRegex = /[^\d.-]/g;
export const nonNumericRegexExcludeDotDash = /[^\d]/g;
export const decimalRegex = /^[.\d]/;
export const integerRegex = /^\d/;
export const specialCharactersRegex = /^[^!@#$%^&*()<>"]*$/;
export const fileNameRegex = /^[\w\-. ]+$/;
export const phoneNumberRegex = /^(?:\d{2}-\d{3}-\d{3}-\d{3}|\d{11})$/;
export const nameWithUnicodeHyphenApostropheOrSpaceRegex = /^[-' \p{L}\p{M}]*$/u;
export const spaceRegex = /\s/;
export const fileExtensionRegex = /\.[^/.]+$/;
export const wordRegex = /^\S+/;
export const forbiddenCharactersRegex = /[<>]/;
